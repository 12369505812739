import { HubCore } from "../../api";
import Moment from "react-moment";
import NeuCardLink from "../../Components/NeuCardLink";
import RowFlex from "../../Components/RowFlex";
import React, { useEffect, useState } from "react";
import ColumnFlex from "../../Components/ColumnFlex";
import GridComponent from "../../Components/GridComponent";
import LoaderComponent from "../../Components/LoaderComponent";

const FilterInput = ({ value, setValue, label, search }) => {
    const onKeyPress = (e) => {
        if (e.charCode === 13) search();
    };

    return (
        <ColumnFlex className="ml-4">
            <p className="font-bold text-lg text-gray-600 flex-1">{label}</p>
            <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="px-4 py-2 mt-2 rounded"
                placeholder={label}
                onKeyPress={onKeyPress}
            />
        </ColumnFlex>
    );
};

const SubscriptionsView = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [ownerName, setOwnerName] = useState("");
    const [businessTitle, setBusinessTitle] = useState("");
    const [solutionTitle, setSolutionTitle] = useState("");
    const [sort, setSort] = useState("newest");

    const fetchSubscriptions = (clearList = false) => {
        let url = `/subscriptions/?offset=${clearList ? 0 : subscriptions.length}`;
        if (ownerName) url += `&owner=${ownerName}`;
        if (businessTitle) url += `&business=${businessTitle}`;
        if (solutionTitle) url += `&solution=${solutionTitle}`;
        if (sort) url += `&sort=${sort}`;
        HubCore.get(url).then((response) => {
            if (clearList) setSubscriptions(response.data);
            else setSubscriptions([...response.data, ...subscriptions]);
        });
    };

    const search = () => fetchSubscriptions(true);
    useEffect(() => {
        fetchSubscriptions();
    }, []);

    return (
        <ColumnFlex>
            <RowFlex className="mb-4">
                <FilterInput value={ownerName} setValue={setOwnerName} label="إسم الزبون" search={search} />
                <FilterInput value={businessTitle} setValue={setBusinessTitle} label="إسم الشركة" search={search} />
                <FilterInput value={solutionTitle} setValue={setSolutionTitle} label="إسم الخدمة" search={search} />
                <ColumnFlex>
                    <p className="font-bold text-lg text-gray-600">ترتيب</p>
                    <select className="font-bold text-lg text-gray-600 px-4 mt-2" value={sort} onChange={(e) => setSort(e.target.value)}>
                        <option value="newest">الأحدث إشتراكًا</option>
                        <option value="oldest">الأقدم إشتراكًا</option>
                        <option value="furthest">الأبعد صلاحية</option>
                        <option value="nearest">الأقرب صلاحية</option>
                    </select>
                </ColumnFlex>
            </RowFlex>
            <button className="px-4 py-2 mb-4 self-start rounded bg-green-500 font-bold text-gray-50" onClick={() => search()}>
                بحث
            </button>
            {subscriptions ? (
                <GridComponent>
                    {subscriptions.map((x) => (
                        <NeuCardLink key={x.id} link={`/businesses/${x.businessId}`}>
                            <div className="p-4">
                                <p className="font-bold text-sm text-gray-800">
                                    <span className="text-lg text-gray-600">{x.businessTitle}</span>
                                    <br />
                                    <span>{x.solutionTitle}</span>
                                    <br />
                                    <span>{x.TierTitle}</span>
                                    <br />
                                    <span>{x.createdByName || ""}</span>
                                </p>
                                <Moment className="text-green-700" fromNow>
                                    {x.startedAt}
                                </Moment>
                                <br />
                                <Moment className="text-red-700" fromNow>
                                    {x.endsAt}
                                </Moment>
                            </div>
                        </NeuCardLink>
                    ))}
                </GridComponent>
            ) : (
                <LoaderComponent />
            )}
        </ColumnFlex>
    );
};

export default SubscriptionsView;
